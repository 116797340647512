<template>
  <div>
    <KTCard title="Company Details">
      <template v-slot:body>
        <b-row>
          <b-col lg="6">
            <base-input
              v-model="companyDetails.company_name"
              label="Company name"
              :disabled="readonly"
            ></base-input>
          </b-col>
          <b-col lg="6">
            <base-input
              v-model="companyDetails.company_registration_number"
              label="Company Registration Number"
              :disabled="readonly"
            ></base-input>
          </b-col>

          <b-col lg="6">
            <base-input
              v-model="companyDetails.ssm"
              label="SSM"
              :disabled="readonly"
            ></base-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6">
            <base-input
              v-model="companyDetails.company_email"
              label="Company Email"
              :disabled="readonly"
            ></base-input>
          </b-col>
          <b-col lg="6">
            <base-input
              v-model="companyDetails.company_phone_number"
              label="Company Phone Number"
              :disabled="readonly"
            ></base-input>
          </b-col>
        </b-row>
      </template>
    </KTCard>
    <KTCard title="Person In Charge Details">
      <template v-slot:body>
        <p class="small">
          The details in this section will only be used for verification
          purposes.
        </p>
        <b-form>
          <b-row>
            <b-col lg="6">
              <base-input
                v-model="companyDetails.pic_name"
                label="Name"
                :disabled="readonly"
              ></base-input>
            </b-col>
            <b-col lg="6">
              <base-input
                type="number"
                v-model="companyDetails.pic_phone_number"
                label="Phone Number"
                :disabled="readonly"
              ></base-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="6">
              <base-input
                v-model="companyDetails.pic_email"
                label="Email"
                :disabled="readonly"
              ></base-input>
            </b-col>
          </b-row>
        </b-form>
      </template>
    </KTCard>
    <KTCard title="Bank Account Details">
      <template v-slot:body>
        <b-row>
          <b-col lg="6">
            <base-input
              v-model="companyDetails.bank_name"
              label="Bank name"
              :disabled="readonly"
            ></base-input>
          </b-col>
          <b-col lg="6">
            <base-input
              v-model="companyDetails.bank_details"
              label="Account Number"
              :disabled="readonly"
            ></base-input>
          </b-col>
        </b-row>
      </template>
    </KTCard>
    <KTCard title="Documents">
      <template v-slot:body>
        <b-form-group>
          <!-- Uploaded File list -->
          <b-row align-v="center">
            <b-col>
              <!-- <p>
                Selected Files:
                <strong> {{ selectedFiles.length }}</strong>
              </p> -->
              <div
                v-for="(file, index) in companyDetails.companyFiles"
                :key="index"
              >
                <b-row class="mb-3">
                  <b-col>
                    <b-form-input :value="file.filename" readonly></b-form-input
                  ></b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
          <!-- END Uploaded File list -->
        </b-form-group>
      </template>
    </KTCard>

    <b-row>
      <b-col class="d-flex flex-row-reverse">
        <!-- <b-button @click="onSubmit" class="ml-3" variant="primary" size="lg"
          >Submit</b-button
        > -->
        <b-button
          variant="secondary"
          size="lg"
          @click="closeModal('CompanyDetailsModal')"
          >Close</b-button
        >
      </b-col>
    </b-row>

    <!--    <pre v-if="debugMode">{{ $v }}</pre>-->
    <pre v-if="debugMode">{{ companyDetails }}</pre>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  alphaNum,
  minLength,
  maxLength,
  required,
  requiredIf,
  email,
} from "vuelidate/lib/validators";
import UiHelper from "@/core/services/uihelper.service";
import { GA_GET_COMPANY_DETAILS } from "@/core/services/store/modules/globaladmin/administration.module";

export default {
  mixins: [validationMixin],
  components: {},
  props: {
    companyId: { required },
  },
  mounted() {
    let payload = {
      company_id: this.companyId,
    };
    this.$store.dispatch(GA_GET_COMPANY_DETAILS, payload).then((res) => {
      if (res.response_code === 2100) {
        this.companyDetails = res.data;
      } else {
        this.companyDetails = "Nothing";
      }
    });
  },
  data() {
    return {
      debugMode: false,
      readonly: true,
      companyDetails: null,
      tncError: true,
      files: [],
      isLoading: false,
      customFileError: [],
      selectedFiles: [],
      acceptedTnc: true,
      makerForm: {
        username: "",
        password: "",
        email: "",
      },
      checkerForm: {
        username: "",
        password: "",
        email: "",
        phoneNumber: "",
      },
      localMessages: UiHelper.validationMessages(),
      tooltipObj: {
        icon: "fa fa-info-circle",
        message: UiHelper.passwordTooltips(),
      },
      tooltipObjSSM: {
        icon: "fa fa-info-circle",
        message: UiHelper.ssmTooltips(),
      },
      uploadTooltip:
        "Accompanying documents to prove the business entity validity for the company registration number that have been provided. ",
      phoneNumberTooltip: {
        icon: "fa fa-info-circle",
        message: "Example format: 019XXXXXXX",
      },
    };
  },
  validations: {
    companyDetails: {
      bank_name: { required },
      bank_details: { required, minLength: minLength(5) },
      others: {
        required: requiredIf(function() {
          return this.companyAccountDetailsForm.bank_name === "Other";
        }),
      },
      company_name: { required, minLength: minLength(5) },
      company_email: { required, email },
      company_registration_number: {
        required,
        // minLength: minLength(12),
        maxLength: maxLength(12),
        alphaNum,
      },
      company_phone_number: { required },
      ssm: { required },
      pic_name: { required, minLength: minLength(5) },
      pic_phone_number: { required },
      pic_email: { required, email },
    },
    makerForm: {
      username: { required, minLength: minLength(5), alphaNum },
      password: { required, minLength: minLength(5) },
      email: { required, email },
      phoneNumber: { required },
    },
    checkerForm: {
      username: { required, minLength: minLength(5), alphaNum },
      password: { required, minLength: minLength(5) },
      email: { required, email },
      phoneNumber: { required },
    },
  },
  computed: {
    BankListOptions() {
      const bankListReference = [
        {
          name: "Maybank",
          code: "27",
        },
        {
          name: "CIMB Bank",
          code: "35",
        },
        {
          name: "RHB Bank",
          code: "18",
        },
        {
          name: "Bank Islam",
          code: "45",
        },
        {
          name: "Bank Muamalat",
          code: "41",
        },
        {
          name: "Bank Rakyat",
          code: "02",
        },
        {
          name: "Bank Simpanan Nasional",
          code: "10",
        },
        {
          name: "Citibank",
          code: "17",
        },
        {
          name: "Hong Leong Bank",
          code: "24",
        },
        {
          name: "HSBC Bank",
          code: "22",
        },
        {
          name: "OCBC Bank",
          code: "29",
        },
        {
          name: "Public Bank",
          code: "33",
        },
        {
          name: "Affin Bank",
          code: "32",
        },
        {
          name: "AmBank",
          code: "08",
        },
        {
          name: "Agro Bank",
          code: "49",
        },
        {
          name: "Alliance Bank",
          code: "12",
        },
        {
          name: "Al-Rajhi Bank",
          code: "53",
        },
        {
          name: "Bank of China",
          code: "42",
        },
        {
          name: "Bank of America",
          code: "07",
        },
        {
          name: "Bank of Tokyo-Mitsubishi UFJ",
          code: "52",
        },
        {
          name: "BNP Paribas",
          code: "60",
        },
        {
          name: "Deutsche Bank",
          code: "19",
        },
        {
          name: "Industrial & Commercial Bank of China",
          code: "59",
        },
        {
          name: "JP Morgan Chase Bank",
          code: "48",
        },
        {
          name: "Kuwait Finance House",
          code: "47",
        },
        {
          name: "Mizuho Bank",
          code: "73",
        },
        {
          name: "Standard Chartered Bank",
          code: "14",
        },
        {
          name: "Sumitomo Mitsui Banking Corporation",
          code: "51",
        },
        {
          name: "The Royal Bank of Scotland",
          code: "46",
        },
        {
          name: "United Overseas Bank",
          code: "26",
        },
      ];

      let options = [];
      if (bankListReference.length) {
        bankListReference.forEach((bankListReference) => {
          options.push({
            label: bankListReference.name,
            value: bankListReference.name,
          });
        });
      }
      options.push({ label: "Other", value: "Other" });
      return options;
    },
  },
  methods: {},
};
</script>
