<template>
  <b-modal
    :id="modalId"
    title="Company Details"
    :visible="modalVisibility(modalId)"
    @show="onShow()"
    @ok="handleOk()"
    @hide="resetModal()"
    size="xl"
    hide-footer
    no-close-on-backdrop
    ok-only
    ok-title="Done"
  >
    <company-details-form :companyId="companyId"></company-details-form>
  </b-modal>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import CompanyDetailsForm from "@/view/pages/globaladmin/company/forms/CompanyDetailsForm";

export default {
  mixins: [validationMixin],
  components: { CompanyDetailsForm },
  data() {
    return {
      modalId: "CompanyDetailsModal",
      companyId: null,
      isBusy: true,
      form: {
        company_name: "",
      },
    };
  },
  validations: {
    form: {
      company_name: { required },
    },
  },
  computed: {},
  methods: {
    onShow() {
      console.log("showing");
      console.log("this.$parent", this.$parent);
      this.companyId = this.$parent.selectedCompany.id;
    },
    handleOk() {
      console.log("handleOk");
    },
    resetModal() {},
  },
};
</script>
