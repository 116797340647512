<template>
  <div>
    <KTCard title="Registered Company">
      <!--      <template v-slot:toolbar>-->
      <!--        <b-button @click="openModal()" variant="primary" size="sm"-->
      <!--          ><i class="fa fa-plus"></i> Action-->
      <!--        </b-button>-->
      <!--      </template>-->

      <template v-slot:body>
        <b-row v-if="!!tableSettings">
          <b-col cols="6">
            <b-form-group class="m-2" label-size="sm">
              <template v-if="tableSettings.refresh">
                <b-button size="sm" class="mr-2">
                  <i class="fa fa-sync"></i> Refresh
                </b-button>
              </template>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              class="m-2"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
            >
              <template v-if="tableSettings.filter">
                <b-input-group size="sm">
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"
                      >Clear</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </template>
            </b-form-group>
          </b-col>
        </b-row>

        <b-table
          :per-page="tableSettings.perPage"
          :current-page="tableSettings.currentPage"
          :filter="filter"
          striped
          show-empty
          bordered
          hover
          :items="tableItems"
          :fields="fields"
        >
          <template v-slot:cell(actions)="row">
            <b-button-group size="sm">
              <b-button
                @click="onViewDetails(row.item)"
                variant="primary"
                size="sm"
              >
                Details
              </b-button>
              <b-button
                @click="onViewInvoicePage(row.item)"
                variant="info"
                size="sm"
              >
                Invoices
              </b-button>
            </b-button-group>
          </template>
        </b-table>

        <b-pagination
          v-if="tableSettings.row > tableSettings.perPage"
          class="pl-0"
          v-model="currentPage"
          :total-rows="tableSettings.rows"
          :per-page="tableSettings.perPage"
        ></b-pagination>
      </template>
    </KTCard>
    <company-details-modal></company-details-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/modules/breadcrumbs.module";
import { GET_COMPANY_LIST } from "@/core/services/store/modules/globaladmin/administration.module";

import CompanyDetailsModal from "@/view/pages/globaladmin/company/modals/CompanyDetailsModal";

export default {
  components: { CompanyDetailsModal },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Global Admin" },
      { title: "Registered Company" },
    ]);
    this.$store.dispatch(GET_COMPANY_LIST, this.defaultPayload);
  },
  computed: {
    tableSettings() {
      const list = this.$store.state.globaladmin_administration.companyList;
      let perPage = 20;
      let listLength;
      if (Array.isArray(list) && list.length) {
        listLength = list.length;
      } else {
        listLength = 100;
      }
      return {
        row: listLength,
        perPage: perPage,
        refresh: false,
        filter: true,
      };
    },
    tableItems() {
      const list = this.$store.state.globaladmin_administration.companyList;
      console.log(list);
      if (Array.isArray(list) && list.length) {
        return list;
      } else {
        return [];
      }
    },
  },
  data() {
    return {
      selectedCompany: null,
      filter: "",
      currentPage: 1,
      fields: [
        { key: "id" },
        { key: "company_name" },
        {
          key: "Actions",
          class: "text-right",
        },
      ],
    };
  },

  methods: {
    onViewDetails(row) {
      // assign selected client
      this.selectedCompany = row;
      // open permission modal
      this.openModal("CompanyDetailsModal");
    },
    onViewInvoicePage(row) {
      // assign selected client
      console.log(row);
      this.selectedCompany = row;
      // open permission modal
      this.$router.push({
        path: "company/invoice",
        query: { id: row.id },
      });
    },
  },
};
</script>
